import "./Info.scss"

export default function Info(
    props: {
        text: string,
        geneId: string
    }
) {
    return <div className={"info"}>
        {props.geneId &&
            <p className={"info-row"}>{props.text} <b>{props.geneId}</b></p>}
    </div>
}