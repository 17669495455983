import { Divider } from "@mui/material"
import "./home.scss"
import { Link } from "react-router-dom"

export default function Home() {
    return (
    <div className={"container"}>
        <div className={"home-container"}>
            <p className={"title"}>
                A DATABASE FOR COORDINATED ANALYSIS OF ARABIDOPSIS GENE COPY NUMBER - METHYLATION - EXPRESSION VARIATION
            </p>

                    One of the strategies that guarantee evolutionary success of plants, is their intraspecies variation. The genetic diversity
            of individuals increases the potential of the entire population to respond to internal and external signals and
            eventually, to survive, adapt and evolve. In the spectrum of known genetic variants, large duplications, deletions and
            insertions (a.k.a copy number variations, or CNVs) have a large potential to influence gene expression and
            consequently - phenotype. Cytosine methylation in genomic DNA is involved in controlling chromatin accessibility for
            the transcription machinery and helps mitigate the undesired effects of gene duplications and transposable elements
            insertions, by silencing respective genomic regions.

            <div className="image-container">
                <img src={require("../../resources/homepage_picture.png")} alt="homepage"/>
            </div>

            The GenExVar database integrates the information about CNVs, transcriptome and methylome in a model plant
            Arabidopsis thaliana, in a gene-centered view mode (<Link to={"/gene/search"}>GENE</Link>). Additionally, gene co-expression modules are presented
            (<Link to={"/wgcna/search"}>WGCNA</Link>). The database combines data for accessions from 1001 Genomes Project collection, obtained in different
            studies as well as previously unpublished data (<Link to={"/about"}>ABOUT</Link>).
            <br></br><br></br>
            Please note that this resource is still under development. If you have questions or comments regarding its content,
            please contact Agnieszka Żmieńko (<a href="mailto:akisiel@ibch.poznan.pl">akisiel@ibch.poznan.pl</a>).

        </div>

        <Divider variant="middle"/>

    </div>
    )
}