import "./BoxChart.scss"
import CanvasJSReact from '@canvasjs/react-charts';
import {BoxChartDataGroups} from "../../domain/BoxChartDataGroups";


export default function BoxChart(props: {
    chartTitle: string,
    chartSubtitleFirst: string,
    chartSubtitleSecond: string,
    chartSubtitleThird: string,
    dataPoints: BoxChartDataGroups | undefined,
    xAxisName: string, yAxisName: string,
    plotContainerHeight: string,
    yValueFormatString: string,
}) {
    const options = {
        theme: "light2",
        animationEnabled: true,
        exportEnabled: true,
        exportFileName: props.chartSubtitleFirst + " - " + props.chartTitle + " (boxplot)",
        title: {
            text: props.chartTitle
        },
        subtitles: [
            {
                text: props.chartSubtitleFirst + "      " + props.chartSubtitleSecond
            },
            /*{
                text: props.chartSubtitleSecond
            },*/
            {
                text: props.chartSubtitleThird,
                //fontColor: "black",

                padding: {
                    top: 0,
                    right: 0,
                    bottom: 30,
                    left: 0
                  }
            }
        ],
        axisY: {
            title: props.yAxisName,
            minimum: 0
        },
        axisX: {
            title: props.xAxisName,
            maximum: 7,
            interval: 2
        },
        toolTip: {
            updated: function(e) {
                if(!showToolTip)
                  e.chart.toolTip.hide();
              }
        },
        // box width
        dataPointMaxWidth: 50,
        data: [
            {
                // [Minimum, Q1, Q3, Maximum, Q2]
                type: "boxAndWhisker",
                toolTipContent: "<b>group {x}:</b> <br> <b>max:</b> {y[3]} <br> <b>Q3:</b> {y[2]} <br> <b>Q2:</b> {y[4]} <br> <b>Q1:</b> {y[1]} <br> <b>min:</b> {y[0]}",

                whiskerThickness: 2,
                whiskerColor: "black",
                whiskerLength: 20,
                
                stemThickness: 2,
                stemColor: "black",

                lineThickness: 2,
                lineColor: "black",
                
                // box color
                color: "#83ae22",
                
                upperBoxColor: "#83ae22",
                
                lowerBoxColor: "#83ae22",
                
                mouseout: onMouseout,
                mouseover: onMouseover,
                dataPoints: props.dataPoints?.getBoxPlotData(),
            },
            {
                type: "scatter",
                toolTipContent: "{y}",
                markerSize: 6,
                color: "black",
                mouseout: onMouseout,
                mouseover: onMouseover,
                dataPoints: props.dataPoints?.getScatterPlotData()
            },
        ]
    }

    let showToolTip = false;

    function onMouseover(e) {
        showToolTip = true;
    }

    function onMouseout(e) {
        showToolTip = false;
    }

    const containerProps = {
        height: props.plotContainerHeight
    };

    return (
        <div className={"chart-container"}>
            <CanvasJSReact.CanvasJSChart containerProps={containerProps} options={options}
            />
        </div>
    );
}
