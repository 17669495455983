import React, {useEffect, useReducer, useState} from 'react';
import Search from "../../components/Search/Search"
import ScatterChart from "../../components/ScatterChart/ScatterChart";
import BoxChart from "../../components/BoxChart/BoxChart"
import {useParams, Link} from "react-router-dom";
import {CircularProgress, Divider} from "@mui/material";
import './gene.scss'
import {ChartType, IGene, IIsData} from "../../model/IGene";
import {BoxChartDataGroups} from "../../domain/BoxChartDataGroups";
import {DataPointColored} from "../../domain/DataPointColored";
import {getCorrelation, getGene} from "../../api/api";
import {CorrelationData} from "../../domain/CorrelationData";
import Info from '../../components/Info/Info';
import { ICorrelation } from '../../model/ICorrelation';


export function getDataForCommonAccessions(xData: DataPointColored[] | undefined, yData: DataPointColored[] | undefined): DataPointColored[] {
    if (xData === undefined || yData === undefined)
        return []
    const outData: DataPointColored[] = []
    for (let i = 0; i < xData.length; i++) {
        let pap = yData.find(d => d.accessionID === xData[i].accessionID)
        if (pap !== undefined) {
            let out: DataPointColored = {x: pap.y, y: xData[i].y, accessionID: xData[i].accessionID, color: xData[i].color}
            outData.push(out)
        }
    }
    return outData
}

export default function Gene(props: {searchOptions: string[]}) {

    const id = useParams()["id"]

    // prevents blurry charts (doesn't work)
    //const [state, updateState] = React.useState("")
    const [, updateState] = useReducer(x => x + 1, 0)

    const [isLoadingGene, setIsLoadingGene] = useState(false)
    const [isLoadingCorr, setIsLoadingCorr] = useState(false)
    //const firstUpdate = useRef(true)

    //const [searchOptions, setSearchOptions] = useState<string[]>()
    const searchOptions = props.searchOptions
    const [geneID, setGeneID] = useState<string>(String(id))
    const [gene, setGene] = useState<IGene | undefined>(undefined)
    const [currentGene, setCurrentGene] = useState<string>("")
    const [correlationValues, setCorrelationValues] = useState<ICorrelation | undefined>(undefined)

    const handleSearchCallback = (childData: string) => {
        setGeneID(childData)
        window.history.pushState({}, "", `${childData}`)
    }

    // get list of genes (moved to App for performance)
    /*useEffect(() => {
        setCurrentGene("")
        
        getGeneIdList().then((res) => {
            const geneIds = res.map(s => s.geneId)
            setSearchOptions(geneIds)
            //console.log("Gene", "getGeneIdList() = ", geneIds)
        })
    }, [])*/

    // correlation values for big chart and box plots
    useEffect(() => {
        //console.log("try to get correlation", geneID)

        if (geneID !== "undefined") {

            setIsLoadingCorr(true)

            getCorrelation(geneID).then((res) => {
                try {
                    const correlation = res
                    setCorrelationValues(correlation)
                    //console.log("correlation values:", correlation)
                } catch(err) {
                    console.error(err)
                }
            }).finally(() => {
                setIsLoadingCorr(false)
                updateState()
            })
        }

    }, [geneID])

    // get specific gene
    useEffect(() => {
        //console.log("try to get gene", typeof(geneID), geneID)

        if (geneID !== "undefined") {
            /*if (firstUpdate.current) {
                firstUpdate.current = false
                return
            }*/

            setIsLoadingGene(true)

            getGene(geneID).then((res) => {
                try {
                    const gene : IGene = res
                    gene.EXPData.type = ChartType.EXP
                    gene.CNData.type = ChartType.CN
                    gene.mCGData.type = ChartType.mCG
                    setGene(gene)
                    setCorrelationData(new CorrelationData(null, null))
                    setCurrentGene(gene.geneId)
                    //console.log("Gene", "getGene() = ", res)
                } catch(err) {
                    console.error(err)
                }
            }).finally(() => {
                setIsLoadingGene(false)
                updateState()
            })
        }

    }, [geneID])


    const [correlationData, setCorrelationData] =
        useState<CorrelationData>(new CorrelationData(null, null))

    const CNVvsmCGBoxPlot = () => {
        if (gene && gene.CNData.isData && gene.mCGData.isData)
            return new BoxChartDataGroups(gene.CNData.dataArray, gene.mCGData.dataArray)
    }
    const CNVvsEXPBoxPlot = () => {
        if (gene && gene.CNData.isData && gene.EXPData.isData)
            return new BoxChartDataGroups(gene.CNData.dataArray, gene.EXPData.dataArray)
    }

    const numberOfCommonAccessions = (dataGroups: BoxChartDataGroups | undefined) => {
        let accesionsNumber: number[] = []

        if (dataGroups) {
            for (let i = 0; i < dataGroups.groups.length; i++) {
                accesionsNumber.push(dataGroups.groups[i].group.length)
            }
        }

        //console.log("mCG Box for", geneID, CNVvsmCGBoxPlot())
        //console.log("EXP Box for", geneID, CNVvsEXPBoxPlot())

        let outputString: string = "N=" + String(accesionsNumber[0])

        if (dataGroups) {
            for (let i = 1; i < accesionsNumber.length; i++) {
                outputString += "                             N=" + accesionsNumber[i]
            }
        }
        
        return(outputString)
    }

    const updateCorrelationData = (newData: IIsData) => {
        setCorrelationData(correlationData => correlationData.setData(newData))
    }

    const textIfAtLeastTwoOptions = () => {
        if (gene !== null) {
            if (gene && ((gene.CNData.isData && gene.EXPData.isData)
                || (gene.CNData.isData && gene.mCGData.isData)
                || (gene.EXPData.isData && gene.mCGData.isData))) {
                return correlationText
            }
        }
        else return null
    }

    return <div className={"container"}>
        <div className="top-bar-container">
            <div className="top-bar-element-left">
                <Search
                    topText={topText}
                    options={searchOptions}
                    label={"Locus ID"}
                    buttonText={"Show Charts"}
                    parentCallback={handleSearchCallback}/>
            </div>
            <div className="top-bar-element-right">
                <Info
                    text="data charts for"
                    geneId={currentGene}
                    />
            </div>
        </div>

        <Divider variant="middle"/>

        {isLoadingGene && isLoadingCorr &&
            <div className="spinner-container"><CircularProgress /></div>}
            
        {!isLoadingGene && !isLoadingCorr && gene &&
            <div className={"content"}>

                {gene?.Module !== "not assigned" && gene?.Module !== "NaN" &&
                    <div className={"wgcna-link-container"}>
                        <Link to={ `/wgcna/${gene?.Module}` }>WGCNA: gene module {gene?.Module}</Link>
                    </div>
                }

                <div className={"top-charts-container"}>

                    {gene.CNData.isData &&
                        <div className={"top-chart"} onClick={() => updateCorrelationData(gene.CNData)}>
                            <ScatterChart
                                isZoomEnabled={true}
                                clickableProps={{canBeSelected: correlationData.canSetData()}}
                                tooltipContent={"accession: {accessionID}, copies: {y}"}
                                dataPoints={gene.CNData.dataArray}
                                chartTitle={"Copy Number"}
                                chartSubtitle={gene.geneId}
                                markerSize={3}
                                xAxisProps={{name: "accession", suffix: ""}}
                                yAxisProps={{name: "copies", suffix: ""}}/>
                        </div>}

                    {gene.EXPData.isData &&
                        <div className={"top-chart"} onClick={() => updateCorrelationData(gene.EXPData)}>
                            <ScatterChart
                                isZoomEnabled={true}
                                clickableProps={{canBeSelected: correlationData.canSetData()}}
                                tooltipContent={"accession: {accessionID}, TPM: {y}"}
                                dataPoints={gene.EXPData.dataArray}
                                chartTitle={"Expression"}
                                chartSubtitle={gene.geneId}
                                markerSize={3}
                                xAxisProps={{name: "accession", suffix: ""}}
                                yAxisProps={{name: "TPM", suffix: ""}}/>
                        </div>}

                    {gene.mCGData.isData &&
                        <div className={"top-chart"} onClick={() => updateCorrelationData(gene.mCGData)}>
                            <ScatterChart
                                isZoomEnabled={true}
                                clickableProps={{canBeSelected: correlationData.canSetData()}}
                                tooltipContent={"accession: {accessionID}, mCG ratio: {y}"}
                                dataPoints={gene.mCGData.dataArray}
                                chartTitle={"Methylation"}
                                chartSubtitle={gene.geneId}
                                markerSize={3}
                                xAxisProps={{name: "accession", suffix: ""}}
                                yAxisProps={{name: "mCG ratio", suffix: ""}}/>
                        </div>}
                </div>

                <Divider variant="middle"/>

                {/*correlationData.canSetData() &&*/
                <div className={"correlation-charts-container-message"}>
                    <p className={"correlation-instruction-text"}>{textIfAtLeastTwoOptions()}</p>
                </div>}

                {!correlationData.canSetData() &&
                    <div className={"correlation-charts-container"}>
                        <ScatterChart
                            isZoomEnabled={true}
                            plotContainerHeight={"100%"}
                            tooltipContent={"accession: {accessionID}, x: {x}, y: {y}"}
                            dataPoints={correlationData.getPlotData()}
                            chartTitle={correlationData.getTitle()}
                            chartSubtitle={gene.geneId}                        
                            chartSubtitleSecond={"correlation: " + correlationValues?.[correlationData.getCorrelationType()].toFixed(2)}
                            markerSize={6}
                            xAxisProps={{name: correlationData.getAxisTitleX(), suffix: ""}}
                            yAxisProps={{name: correlationData.getAxisTitleY(), suffix: ""}}/>
                    </div>}

                <Divider variant="middle"/>

                <div className={"bottom-charts-container"}>

                    {gene.CNData.isData && gene.EXPData.isData &&
                        <div className={"bottom-chart"}>
                            <BoxChart
                                dataPoints={CNVvsEXPBoxPlot()}
                                plotContainerHeight={'100%'}
                                chartTitle={"Copy Number vs Expression"}
                                chartSubtitleFirst={gene.geneId}
                                chartSubtitleSecond={"correlation: " + correlationValues?.corrGECN.toFixed(2)}
                                chartSubtitleThird={String(numberOfCommonAccessions(CNVvsEXPBoxPlot()))}
                                xAxisName={"copy number group"}
                                yAxisName={"TPM"}
                                yValueFormatString={"#,##0.#"}/>
                        </div>
                    }
                    
                    {gene.CNData.isData && gene.mCGData.isData &&
                        <div className={"bottom-chart"}>
                            <BoxChart
                                dataPoints={CNVvsmCGBoxPlot()}
                                plotContainerHeight={'100%'}
                                chartTitle={"Copy Number vs Methylation"}
                                chartSubtitleFirst={gene.geneId}
                                chartSubtitleSecond={"correlation: " + correlationValues?.corrCNCG.toFixed(2)}
                                chartSubtitleThird={String(numberOfCommonAccessions(CNVvsmCGBoxPlot()))}
                                xAxisName={"copy number group"}
                                yAxisName={"mCG ratio"}
                                yValueFormatString={"#,##0.#"}/>
                        </div>
                    }
                </div>
            </div>
        }

        <Divider variant="middle"/>

    </div>
}

const topText = "Enter valid gene locus ID (Araport 11 annotation), e.g. AT1G02250"

const correlationText = "Click two from the above charts to see the correlation between them. Click again to deselect."
