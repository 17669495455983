import {Link} from "react-router-dom"
import "./WgcnaTable.scss"
import { IWgcna } from "../../model/IWgcna"
import { ReactNode } from "react"
import React from "react"

export default function WgcnaTable(
    props: {
        data: IWgcna[]
    }
) {
    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0})
    }

    const createTable = () => {
        let elements: ReactNode[] = []
        elements.push(React.createElement(
            "tr",
            { key: 0 },
            [
                <th>module</th>,
                <th>number of genes</th>,
                <th>hub gene</th>]
        ))

        let i = 0
        props.data.forEach(e => {
            elements.push(React.createElement(
                "tr",
                { key: i + 1 },
                [
                    <td>
                        {props.data[i].module !== "not assigned" &&
                            <Link to={ `/wgcna/${props.data[i].module}`} onClick={scrollToTop}>
                                module {props.data[i].module}
                            </Link>}

                        {props.data[i].module === "not assigned" &&
                            <span>
                                module {props.data[i].module}
                            </span>}
                    </td>,
                    <td>{props.data[i].numGenes}</td>,
                    <td>{props.data[i].Hubgene}</td>]))
            
            i++
        })

        let table = React.createElement(
            "table",
            { className: "wgcnaTable" }, 
            React.createElement(
                "tbody",
                null,
                elements))

        return (<div>{table}</div>)
    }

    return createTable()
}
