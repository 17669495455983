import axios from "axios"
//require("dotenv").config()

const baseUrl = "https://genexvar.ibch.poznan.pl/api" //process.env.REACT_APP_SERVER_URL


export const getGene = async (geneId: string): Promise<any> => {
    try {
        //alert("getGene")
        const response = await axios.get(`${baseUrl}/gene?id=${geneId}`);

        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getGeneIdList = async (): Promise<any> => {
    try {
        //alert("getGeneIdList")
        const response = await axios.get(`${baseUrl}/gene-ids`);

        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getWGCNA = async (wgcnaId: string): Promise<any> => {
    try {
        //alert("getWGCNA")
        const response = await axios.get(`${baseUrl}/wgcna-module?id=${wgcnaId}`);

        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getWGCNAGroupList = async (): Promise<any> => {
    try {
        //alert("getWGCNAGroupList")
        const response = await axios.get(`${baseUrl}/wgcna-modules`);

        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getCorrelation = async (geneId: string): Promise<any> => {
    try {
        //alert("getCorrelation")
        const response = await axios.get(`${baseUrl}/correlation?id=${geneId}`);

        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
