import React from 'react';
import "./ScatterChart.scss";
import CanvasJSReact from '@canvasjs/react-charts';
import {DataPointColored} from "../../domain/DataPointColored";
import {getDataWithLegend, showToolTip} from "../../domain/ChartDataWithLegend";


export type AxisProps = {
    name: string
    suffix: string
}

export type IClickableProps = {
    canBeSelected: boolean
}

export type ScatterChartProps = {
    chartTitle: string,
    chartSubtitle: string,
    chartSubtitleSecond?: string,
    dataPoints: DataPointColored[],
    xAxisProps: AxisProps,
    yAxisProps: AxisProps,
    markerSize: number,
    tooltipContent: string,
    plotContainerHeight?: string,
    isZoomEnabled?: boolean
    clickableProps?: IClickableProps
}

// const chartTitleFontSize = 36;
// const axisTitleFontSize = 24;
// const labelFontSize = 16;

export default function ScatterChart(props: ScatterChartProps) {
    const isZoomEnabled = props.isZoomEnabled !== undefined ? props.isZoomEnabled : false
    const isClickable = props.clickableProps !== undefined ? props.clickableProps : false
    const plotContainerHeight = props.plotContainerHeight !== undefined ? props.plotContainerHeight : '100%'

    const [state, setState] = React.useState(false)

    const handleDivClick = () => {
        if (props.clickableProps !== undefined) {
            if (!state && !props.clickableProps.canBeSelected)
                return
            setState(!state)
        }
    }

    const options = {
        theme: "light2",
        animationEnabled: true,
        zoomEnabled: isZoomEnabled,
        exportEnabled: true,
        exportFileName: props.chartSubtitle + " - " + props.chartTitle,
        legend: {
            // fontSize: labelFontSize,
            horizontalAlign: "left", // left, center, right
            verticalAlign: "bottom", // top, center, bottom
        },
        title: {
            // fontSize: chartTitleFontSize,
            text: props.chartTitle
        },
        subtitles: [
            {
                text: props.chartSubtitle
            },
            {
                text: props.chartSubtitleSecond
            }
        ],
        axisX: {
            // titleFontSize: axisTitleFontSize,
            // labelFontSize: labelFontSize,
            title: props.xAxisProps.name,
            suffix: props.xAxisProps.suffix,
            crosshair: {
                enabled: true,
                snapToDataPoint: false,
                label: ""
            }
        },
        axisY: {
            // titleFontSize: axisTitleFontSize,
            // labelFontSize: labelFontSize,
            title: props.yAxisProps.name,
            suffix: props.yAxisProps.suffix,
            crosshair: {
                enabled: true,
                snapToDataPoint: false,
                label: ""
            },
            //includeZero: false,
            minimum: 0
        },
        toolTip: {
            updated: function(e) {
                if(!showToolTip)
                  e.chart.toolTip.hide();
              }
        },
        data:  getDataWithLegend(props.dataPoints, props.tooltipContent, props.markerSize)
    }

    const containerProps = {
        height: plotContainerHeight
    };

    return (
        <div
            style={{borderColor: state ? "blue" : "lightgrey", borderWidth: 1}}
            className={"chart-container"}
            onClick={isClickable ? handleDivClick : (() => {})}>
            <CanvasJSReact.CanvasJSChart containerProps={containerProps} options={options}/>
        </div>
    );
}
