import TextField from "@mui/material/TextField";
import React, {SyntheticEvent/*, useState*/} from "react";
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';
import "./Search.scss"
//import Button from "@mui/material/Button";
import { createFilterOptions } from "@mui/material";


export default function Search(
    props: {
        topText: string,
        options: string[],
        label: string,
        buttonText: string,
        parentCallback: (data: string) => void
    }) {

    //const [searchValue, setSearchValue] = useState("");

    const handleChange = (
            event: SyntheticEvent<Element, Event>,
            value: unknown,
            reason: AutocompleteChangeReason,
            details?: AutocompleteChangeDetails<unknown> | undefined) => {

        if (value != null)
            //setSearchValue(String(value))
            props.parentCallback(String(value))
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        matchFrom: "any",
        limit: 100,
      });

    return (
        <div className="search-container">
            <div className="top-text">
                <p>{props.topText}</p>
            </div>
            <div className="search">
                <Autocomplete
                    disablePortal
                    id="autocomplete-geneiD"
                    autoHighlight={true}
                    filterOptions={filterOptions}
                    options={props.options}
                    sx={{width: 250}}
                    renderInput={(params) => <TextField {...params} label={props.label}/>}
                    onChange={handleChange}
                />
                {/*<Button id="search-button"
                        variant="outlined"
                        onClick={() => props.parentCallback(searchValue)}>
                    {props.buttonText}
                </Button>*/}
            </div>
        </div>
    )
}
