import "./Navbar.scss"
import {NavLink} from "react-router-dom";
import {AppBar, Toolbar, Typography} from "@mui/material";

export default function Navbar(){
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h4" className={"logo"}>
                    <NavLink to="/home" className={"link"}>
                        GenExVar
                    </NavLink>
                </Typography>
                <div className={"navlinks"}>
                    <NavLink to="/gene/search" className={"link"}>
                        GENE
                    </NavLink>
                    <NavLink to="/wgcna/search" className={"link"}>
                        WGCNA
                    </NavLink>
                    <NavLink to="/about" className={"link"}>
                        ABOUT
                    </NavLink>
                </div>
            </Toolbar>
        </AppBar>
    )
}
