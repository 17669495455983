import {Divider} from "@mui/material";
import {Text} from "react-native-web"
import "./about.scss";

export default function About() {
    return (
    <div className={"container"}>
        <div className={"about-container"}>
            <Text>
                <p>
                    {
`Source data:

GENES - Arabidopsis thaliana protein-coding genes - Araport11 annotation. Annotations available from: https://www.arabidopsis.org/

ACCESSIONS - 1001 Genomes Project accessions. IDs and details available from: https://www.1001genomes.org

CN - gene copy number genotypes. Details available in [Zmienko A, Marszalek-Zenczak M, Wojciechowski P, Samelak-Czajka A, Luczak M, Kozlowski P, Karlowski WM, Figlerowicz M. AthCNV: A Map of DNA Copy Number Variations in the Arabidopsis Genome. Plant Cell. 2020 Jun;32(6):1797-1819. doi: 10.1105/tpc.19.00640] Data available from: https://ibch.poznan.pl/athcnv

EXP - gene expression data from a study by [Kawakatsu T, Huang SC, Jupe F, Sasaki E, Schmitz RJ, Urich MA, Castanon R, Nery JR, Barragan C, He Y, Chen H, Dubin M, Lee CR, Wang C, Bemm F, Becker C, O'Neil R, O'Malley RC, Quarless DX; 1001 Genomes Consortium; Schork NJ, Weigel D, Nordborg M, Ecker JR. Epigenomic Diversity in a Global Collection of Arabidopsis thaliana Accessions. Cell. 2016 Jul 14;166(2):492-505. doi: 10.1016/j.cell.2016.06.044]. Normalized gene counts were downloaded from GEO repository at the National Center for Biotechnology Information (GSE80744) and converted to TPM values. Only genes present in Araport11 annotation were retained for the analysis.

mCG - CG-methylation data from a study by [Kawakatsu T, Huang SC, Jupe F, Sasaki E, Schmitz RJ, Urich MA, Castanon R, Nery JR, Barragan C, He Y, Chen H, Dubin M, Lee CR, Wang C, Bemm F, Becker C, O'Neil R, O'Malley RC, Quarless DX; 1001 Genomes Consortium; Schork NJ, Weigel D, Nordborg M, Ecker JR. Epigenomic Diversity in a Global Collection of Arabidopsis thaliana Accessions. Cell. 2016 Jul 14;166(2):492-505. doi: 10.1016/j.cell.2016.06.044]. Processed gene body methylation data (CG-context only) were downloaded from http://signal.salk.edu/1001.php. Only genes present in Araport11 annotations were retained. Additionally, genes with methylation data in less than 400 accessions were filtered out.

WGCNA - CO-expression clusters were identified with WGCNA package [Langfelder P, Horvath S. WGCNA: an R package for weighted correlation network analysis. BMC Bioinformatics. 2008 Dec 29;9:559. doi: 10.1186/1471-2105-9-559.] using gene expression table downloaded from ATTED-II.


`
                    }
                </p>
            </Text>
        </div>
        <Divider variant="middle"/>
    </div>)
}
