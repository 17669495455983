import './App.css';
import Navbar from "./components/Navbar/Navbar";
import {Route, Routes, Navigate} from 'react-router-dom';
import Home from "./pages/home/home";
import Gene from "./pages/gene/gene";
import Wgcna from "./pages/wgcna/wgcna";
import About from "./pages/about/about";
import Footer from "./components/Footer/Footer";
import { useEffect, useState } from 'react';
import { getGeneIdList } from './api/api';
//require("dotenv").config()

 
function App() {

    const [searchOptions, setSearchOptions] = useState<string[]>([])

    // get list of genes
    useEffect(() => {
        getGeneIdList().then((res) => {
            const geneIds = res.map(s => s.geneId)
            setSearchOptions(geneIds)
            //console.log("Gene", "getGeneIdList() = ", geneIds)
        })
    }, [])

    return (
        <div className="App">
            <Navbar/>
            <Routes>
                <Route path="/home" element={<Home/>}/>
                <Route path="/gene/search" element={<Gene searchOptions={searchOptions}/>}/>
                <Route path="/gene/:id" element={<Gene searchOptions={searchOptions}/>}/>
                <Route path="/gene" element={<Gene searchOptions={searchOptions}/>}/>
                <Route path="/wgcna/search" element={<Wgcna/>}/>
                <Route path="/wgcna/:id" element={<Wgcna/>}/>
                <Route path="/wgcna" element={<Navigate to="/wgcna/search" replace/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="*" element={<Navigate to="/home" replace/>}/>
            </Routes>
            <Footer/>
        </div>
    );
}


export default App;
