import React, {useEffect, useState} from 'react';
import Search from "../../components/Search/Search";
import {Divider} from "@mui/material";
import WgcnaInfo from "../../components/WgcnaInfo/WgcnaInfo";
import {IWgcna} from "../../model/IWgcna";
import {getWGCNA, getWGCNAGroupList} from "../../api/api";
import { useParams } from 'react-router-dom';
import WgcnaTable from '../../components/WgcnaTable/WgcnaTable';


export default function Wgcna() {

    const id = useParams()["id"]

    const [searchOptions, setSearchOptions] = useState<string[]>([])
    const [wgcnaID, setWgcnaID] = useState<string>(String(id))
    const [wgcna, setWgcna] = useState<IWgcna | undefined>(undefined)
    const [wgcnaTable, setWgcnaTable] = useState<IWgcna[] | undefined>(undefined)


    const handleCallback = (childData: string) => {
        setWgcnaID(childData)
        window.history.pushState({}, "", childData)
    }

    useEffect(() => {   
        setWgcnaID(String(id))
    }, [id])

    useEffect(() => {
        getWGCNAGroupList().then((res) => {

            setWgcnaTable(res)
            //console.log("wgcnaTable:", wgcnaTable)
            
            let wgcnaModules = res.map(s => Number(s.module))

            wgcnaModules = wgcnaModules.filter(function(s) {
                if (isNaN(s)) {
                    return false
                }
                return true
            })
            
            wgcnaModules = wgcnaModules.map(s => String(s))

            setSearchOptions(wgcnaModules)
            //console.log("WGCNAGroupList", "getWGCNAGroupList() = ", wgcnaModules)
        })
    }, [])

    useEffect(() => {        
        getWGCNA(wgcnaID).then((res) => {
            const wgcna: IWgcna = res
            setWgcna(wgcna)
            //console.log("WGCNA", "getWGCNA() = ", res)
        })
    }, [wgcnaID])


    return (
    <div className={"container"}>

        <Search topText={topText}
            options={searchOptions}
            label={"wgcna"}
            buttonText={"Show Data"}
            parentCallback={handleCallback}/>

        <Divider variant="middle"/>

        {wgcna &&
            <WgcnaInfo
                moduleName={wgcna.module.toString()}
                genesCount={wgcna.numGenes.toString()}
                hubGeneId={wgcna.Hubgene}
                genesInModule={wgcna.Genes}/>}

        <Divider variant="middle"/>

        {wgcnaTable &&
            <WgcnaTable data={wgcnaTable}/>}

        <Divider variant="middle"/>

    </div>)
}

const topText = "Enter valid wgcna group";
