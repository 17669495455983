import {Link} from "react-router-dom"
import "./WgcnaInfo.scss"

export default function WgcnaInfo(
    props: {
        moduleName: string,
        genesCount: string,
        hubGeneId: string,
        genesInModule: string[]
    }
) {
    return (
    <div className={"wgcnaInfo"}>
        <p className={"wgcnaInfo-row"}>Module ID: {props.moduleName}</p>
        <p className={"wgcnaInfo-row"}>
            Hub Gene: <Link to={ `/gene/${props.hubGeneId}` }>
                {props.hubGeneId}
            </Link>
        </p>
        <p className={"wgcnaInfo-row"}>Number of genes: {props.genesCount}</p>
        <p className={"wgcnaInfo-row"}>
            Genes in module: <br></br>
            {props.genesInModule.join(", ")}
        </p>
    </div>)
}
